.lp-container {
    background-color: #f9f9f9;
    display: grid;
    min-height: 91vh;
    padding: 2rem;
    gap: 2rem;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 2fr 1fr;   
    grid-template-areas:
        "chat1 chat2"                
        "btml btmr"; 
}

.lp-container p {
    margin: 0;
    padding: 0;
    border-style: none;
    text-align: center;
}

.lp-chat-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid rgb(216, 216, 216);
    padding-bottom: 1rem;
    gap: 1rem;
    border-radius: 8px;
    background-color: white;
    transition: transform 0.5s ease;
}

.lp-chat-container:hover {
    transform: scale(1.01);
}

.lp-logo-container {
    padding-top: 2rem;
}

.lp-sc-logo {
    height: 130px;
}

.lp-tc-logo{
    height: 130px;
}

.lp-chat-1 {
    grid-area: chat1;
}

.lp-chat-2 {
    grid-area: chat2;
}

.lp-button {
    background-color: var(--clr-primary-500);
    color: white;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 13px 18px 13px 18px;
    min-width: 8rem;
    font-size: var(--fs-600);
}

.lp-button:hover {
    background-color: #5ea18b;
}

.lp-button-box {
    color: black;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 5px;
    min-width: 8rem;
    font-size: var(--fs-400);
}

@media (max-width: 480px) {
    .lp-button-box {
        display: none;
    }
}

.lp-chat-text {
    margin: 0 3rem 0 3rem;
}

.lp-btm-container-1 {
    grid-area: btml;
    display: flex;
    gap: 2rem;
    cursor: pointer;
}

.lp-btm-container-2 {
    grid-area: btmr;
    display: flex;
    gap: 2rem;
    cursor: pointer;
}

.lp-btm-box {
    flex: 1; 
    border: 2px solid black;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid rgb(216, 216, 216);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
    transition: transform 0.5s ease;
}

.lp-btm-box:hover {
    transform: scale(1.01);
}

.lp-stat-container > p:first-child{
    color: var(--clr-primary-500);
    font-weight: bold;
}

@media (max-width: 1050px) {
    .lp-container {
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 0fr 0fr;
        grid-template-areas:
            "chat1"  
            "chat2"              
            "btml"
            "btmr";
    }

    .lp-btm-container-1 {
        gap: 1rem;
    }

    .lp-btm-container-2 {
        gap: 1rem;
    }

    .lp-sc-logo {
        height: 75px;
    }
    
    .lp-tc-logo{
        height: 75px;
    }

    .lp-chat-container {
        justify-content: none;
    }

    .lp-chat-text {
        display: none;
    }
}

@media (min-width: 1600px) {
    .lp-container p {
        font-size: var(--fs-900);
    }
}